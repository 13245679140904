import * as React from "react";
import Loadable from "@loadable/component";
import * as http from "../../../utils/http";

const SignupModal = Loadable(() => import("./signup-modal"));
const ThankYouModal = Loadable(() => import("./thank-you-modal"));

const SignupContext = React.createContext();

export const SignupContextProvider = ({ children }) => {
  const [state, setState] = React.useState("initial");

  const handleSubmit = React.useRef(async (fields) => {
    await http.post("/api/signup", fields);
    setState("success");
  });

  const Modal = React.useCallback(() => {
    switch (state) {
      case "signup":
        return (
          <SignupModal
            onSubmit={handleSubmit.current}
            onClose={() => setState("initial")}
          />
        );
      case "success":
        return <ThankYouModal onClose={() => setState("inital")} />;
      default:
        return null;
    }
  }, [state]);

  const startSignup = React.useRef(() => {
    setState("signup");
  });

  return (
    <SignupContext.Provider value={{ Modal, startSignup: startSignup.current }}>
      {children}
    </SignupContext.Provider>
  );
};

const useSignup = () => {
  return React.useContext(SignupContext);
};

export default useSignup;
